import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Analytics from '../../analytics/Analytics';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Button } from '../../components/button/Button';
import { Layout } from '../../components/layout/Layout';
import PartnerEventBanner from '../../components/layout/banners/partner-event-banner/PartnerEventBanner';
import { TextField } from '../../components/text-field/TextField';
import name from '../../content/ui/screens/name/name';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import CustomerService from '../../services/customer-service/CustomerService';
import commonStrings from '../../strings/common';
import Routes from '../../utils/Routes';
import Limits from '../../utils/constants/Limits';

import './Name.scss';

const contentMap = {
    heading: 'ui.heading',
    disclaimer: 'ui.disclaimer',
    firstName: 'ui.firstName',
    lastName: 'ui.lastName',
    seePrices: 'ui.seePrices',
};

interface NameProps {
    content: Record<keyof typeof contentMap, string>;
}

const Name: React.FC<NameProps> = ({ content }) => {
    const {
        userDetails,
        setUserDetailsByAttr,
        accessToken,
        residencyCompleted,
        totalPurchases: tpLazyDepObj,
    } = useUser();
    const totalPurchases = useLazyDependency(tpLazyDepObj);
    const userHasCover = totalPurchases.value && totalPurchases.value > 0;
    const [firstName, setFirstName] = useState(userDetails.firstName || '');
    const [lastName, setLastName] = useState(userDetails.lastName || '');

    const history = useHistory();

    const location = useLocation<LocationState>();
    const isOnboardingFlow = location.state ? location.state.isOnboarding : false;

    const [isLoading, setIsLoading] = useState(false);

    const [error, setError] = useState('');
    const productsPageUrl = residencyCompleted ? Routes.SELECT_COVER : Routes.RESIDENCY_STATE;

    const handleContinueClick = async () => {
        setError('');

        // Update global state
        setUserDetailsByAttr('firstName', firstName);
        setUserDetailsByAttr('lastName', lastName);

        Analytics.identify({ first_name: firstName, last_name: lastName });

        setIsLoading(true);

        try {
            await CustomerService.updateCustomerProfile({
                accessToken: accessToken!,
                profile: { firstName, lastName },
            });

            if (userHasCover) {
                history.push(Routes.HOME);
            } else {
                const nextPath = productsPageUrl;

                // Navigate to next state
                history.push({
                    pathname: nextPath,
                    state: { isOnboarding: isOnboardingFlow },
                });
            }
        } catch (err) {
            console.log(err);
            setError(commonStrings.errorSomethingWentWrong);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout
            title={content.heading}
            showBackButton={!isOnboardingFlow}
            showLoading={isLoading}
            description={content.disclaimer}
            banner={<PartnerEventBanner />}
        >
            <form>
                <TextField
                    id="firstName"
                    name="firstName"
                    label={content.firstName}
                    value={firstName}
                    minLength={Limits.NAME_MIN_LENGTH}
                    maxLength={Limits.NAME_MAX_LENGTH}
                    isError={!!error}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    id="lastName"
                    name="lastName"
                    label={content.lastName}
                    className="name__last-name"
                    value={lastName}
                    minLength={Limits.NAME_MIN_LENGTH}
                    maxLength={Limits.NAME_MAX_LENGTH}
                    isError={!!error}
                    onChange={(e) => setLastName(e.target.value)}
                />

                <Button
                    label={commonStrings.continue}
                    disabled={!(firstName && lastName)}
                    width="full"
                    className="name__btn-continue"
                    onClick={handleContinueClick}
                />
                {error && <p className="error">{commonStrings.errorSomethingWentWrong}</p>}
            </form>
        </Layout>
    );
};

export default requireFlags(withContent(Name, contentMap, name));
