import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { FC, ReactNode, useMemo } from 'react';
import { ProductProvider } from '../../business-logic/context-provider/ProductContext';
import { OpenCoversProvider } from '../../business-logic/context-provider/OpenCoversContext';
import { ReferralProvider } from '../../business-logic/context-provider/ReferralContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import ToastProvider from '../../components/toast/ToastProvider';
import ZendeskProvider from '../../components/zendesk/Zendesk';

const LAUNCH_DARKLY_CLIENT_SIDE_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID || '';

/**
 * Adds LaunchDarkly provider and Cover Provider
 *
 * @param param0
 * @returns
 */
const Providers: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDetails } = useUser();

    const AllProviders = (props: any) => (
        <ZendeskProvider>
            <ToastProvider>
                <ReferralProvider>
                    <OpenCoversProvider>
                        <ProductProvider {...props} />
                    </OpenCoversProvider>
                </ReferralProvider>
            </ToastProvider>
        </ZendeskProvider>
    );

    const AllProvidersWithFlags = useMemo(
        () =>
            withLDProvider({
                clientSideID: LAUNCH_DARKLY_CLIENT_SIDE_ID,
                context: userDetails.id ? { kind: 'user', key: userDetails.id } : undefined,
            })(AllProviders),
        [userDetails.id],
    );

    return <AllProvidersWithFlags>{children}</AllProvidersWithFlags>;
};

export default Providers;
