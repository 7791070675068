enum CartStep {
    PROFILE,
    WHOS_COVERED,
    REVIEW_COVER,
    PAYMENT,
}

export const CartStepsWithoutKids = [CartStep.PROFILE, CartStep.REVIEW_COVER, CartStep.PAYMENT];

export const CartStepsWithKids = [CartStep.PROFILE, CartStep.WHOS_COVERED, CartStep.REVIEW_COVER, CartStep.PAYMENT];

export default CartStep;
