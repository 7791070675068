import { assign } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import ProductGroupsResponse from '../../../../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../../../../business-logic/models/ProductResponse';
import CartStep, { CartStepsWithKids, CartStepsWithoutKids } from '../../constants/CartStep';
import { Typegen0 } from '../cartMachine.typegen';
import { CartMachineContextTypes, InsuredPersonType } from '../context/cartMachineContext';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const updateMachineContextFromGlobalContext = assign<
    CartMachineContextTypes,
    {
        type: EventsCausingActions['updateMachineContextFromGlobalContext'];
        data: {
            products: ProductResponse[];
            productGroups: ProductGroupsResponse | null;
            coverInformation: AllCoverInformation | null;
        };
    }
>({
    productGroups: (ctx, event) => event.data.productGroups,
    products: (ctx, event) => {
        return event.data.products;
    },
    coverInformation: (ctx, event) => {
        return event.data.coverInformation;
    },
}) as any;

export const setDependantsAsAvailableInsuredType = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setDependantsAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types = [...ctx.availableInsuredPersonsTypes, 'dependant' as InsuredPersonType];
        return types;
    },
}) as any;

export const setAccountHolderAsAvailableInsuredType = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setAccountHolderAsAvailableInsuredType'] }
>({
    availableInsuredPersonsTypes: (ctx) => {
        const types = [...ctx.availableInsuredPersonsTypes, 'accountholder' as InsuredPersonType];
        return types;
    },
}) as any;

export const markAccountHolderAsInsuredPerson = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['markAccountHolderAsInsuredPerson'] }
>({
    insuredPersons: (ctx) => {
        return {
            accountHolder: true,
            dependants: ctx.insuredPersons.dependants,
        };
    },
}) as any;

export const setPersonsInsured = assign<
    CartMachineContextTypes,
    { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    insuredPersons: (ctx, event) => {
        return {
            accountHolder: event.data.isAccountHolderInsured,
            dependants: event.data.dependants,
        };
    },
}) as any;

export const setCartStepsWithoutKids = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setCartStepsWithoutKids'] }
>({
    steps: () => {
        return CartStepsWithoutKids;
    },
}) as any;

export const setCartStepsWithKids = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setCartStepsWithKids'] }
>({
    steps: () => {
        return CartStepsWithKids;
    },
}) as any;

export const setProfileStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['setProfileStep'] }
>({
    currentStep: () => CartStep.PROFILE,
}) as any;

export const setPersonsInsuredStep = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['setPersonsInsuredStep'] }
>({
    currentStep: () => {
        return CartStep.WHOS_COVERED;
    },
}) as any;

export const setReviewCoverStep = assign<
    CartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    currentStep: () => CartStep.REVIEW_COVER,
}) as any;

export const setPaymentStep = assign<CartMachineContextTypes, { type: EventsCausingActions['setPaymentStep'] }>({
    currentStep: () => CartStep.PAYMENT,
}) as any;

export const showProfileStep = assign<
    CartMachineContextTypes,
    { type: 'TOGGLE_PROFILE' } | { type: EventsCausingActions['showProfileStep'] }
>({
    showStep: () => CartStep.PROFILE,
}) as any;

export const showPersonsInsuredStep = assign<
    CartMachineContextTypes,
    { type: EventsCausingActions['showPersonsInsuredStep'] }
>({
    showStep: () => {
        return CartStep.WHOS_COVERED;
    },
}) as any;

export const showPaymentStep = assign<CartMachineContextTypes, { type: EventsCausingActions['showPaymentStep'] }>({
    showStep: () => {
        return CartStep.PAYMENT;
    },
}) as any;

export const showReviewCoverStep = assign<
    CartMachineContextTypes,
    | { type: Exclude<EventsCausingActions['setReviewCoverStep'], 'PERSONS_INSURED_COMPLETE'> }
    | { type: 'PERSONS_INSURED_COMPLETE'; data: { dependants: string[]; isAccountHolderInsured: boolean } }
>({
    showStep: () => CartStep.REVIEW_COVER,
}) as any;

// // Analytics

export const trackProfileStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartStep.PROFILE + 1, 'Your Details');
};

export const trackPersonsInsuredStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartStep.WHOS_COVERED + 1, 'Who is covered');
};

export const trackReviewCoverStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepViewed = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepViewed(CartStep.PAYMENT + 1, 'Payment Details');
};

export const trackProfileStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartStep.PROFILE + 1, 'Your Details');
};

export const trackSelectedPersonsToInsure = (ctx: CartMachineContextTypes): void => {
    Analytics.trackSelectedPersonsToInsure(ctx.insuredPersons.accountHolder, ctx.insuredPersons.dependants.length);
};

export const trackPersonsInsuredStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartStep.WHOS_COVERED + 1, 'Who is covered');
};

export const trackReviewCoverStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartStep.REVIEW_COVER + 1, 'Review Cover');
};

export const trackPaymentStepCompleted = (): void => {
    // Cart Steps is 0 indexed
    Analytics.trackCheckoutStepCompleted(CartStep.PAYMENT + 1, 'Payment Details');
};

export const trackCheckoutCompleted = (): void => {
    Analytics.trackCheckoutCompleted();
};
