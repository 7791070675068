const ACCOUNT_BASE = '/profile';
const LOGIN_BASE = '/signin';
const PURCHASE_BASE = '/purchase';

export type Route = (typeof Routes)[keyof typeof Routes];

const Routes = {
    // Landing & dashboard
    LANDING: '/',
    HOME: '/dashboard',
    MANAGE_COVER: '/manage-cover',

    // User profile forms
    RESIDENCY_STATE: '/residency-state',
    RESIDENCY_STATUS: '/residency-status',
    DATE_OF_BIRTH: '/date-of-birth',
    GENDER: '/gender',
    NAME: '/name',

    // Auth
    LOGIN: `${LOGIN_BASE}`,
    REGISTER: `${LOGIN_BASE}/register`,
    FORGOT_PASSWORD: `${LOGIN_BASE}/forgot-password`,
    LOGIN_CALLBACK: '/login/callback',

    // Account
    ACCOUNT: ACCOUNT_BASE,
    ACCOUNT_DETAILS: `${ACCOUNT_BASE}/account`,
    ACCOUNT_PREFERENCES: `${ACCOUNT_BASE}/preferences`,
    ACCOUNT_CERTIFICATE_OF_COVER: `${ACCOUNT_BASE}/certificate-of-cover`,

    // Purchase
    SELECT_COVER: `${PURCHASE_BASE}`,
    PURCHASE_ENTRY: `${PURCHASE_BASE}/:selectedProductGroup`,
    SELECT_PRODUCT_OPTIONS: `${PURCHASE_BASE}/:selectedProductGroup/options`,
    SELECT_DESTINATIONS: `${PURCHASE_BASE}/:selectedProductGroup/destinations`,
    SELECT_STARTING_DESTINATION: `${PURCHASE_BASE}/:selectedProductGroup/starting-destination`,
    SELECT_STARTING_REGION: `${PURCHASE_BASE}/:selectedProductGroup/starting-region`,
    SELECT_DATES: `${PURCHASE_BASE}/:selectedProductGroup/select-dates`,
    COVER_DETAILS: `${PURCHASE_BASE}/:selectedProductGroup/cover-details`,
    CART: `${PURCHASE_BASE}/cart`,
    CART_SUCCESS: `${PURCHASE_BASE}/success`,

    // Not found
    NOT_FOUND: '/404',
} as const;

export default Routes;
