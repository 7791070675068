import LocalizedStrings from 'react-localization';
import ExternalLinks from '../../../../utils/constants/ExternalLinks';
import CartStep from '../../../../screens/cart/constants/CartStep';

const cartContent = new LocalizedStrings({
    en: {
        header: 'Checkout',
        steps: {
            [CartStep.PROFILE]: 'Your details',
            [CartStep.WHOS_COVERED]: 'Who’s covered',
            [CartStep.REVIEW_COVER]: 'Cover details',
            [CartStep.PAYMENT]: 'Payment',
        },
        referral: {
            referralVerificationSuccess: 'Complete sign up to get 3 weeks free',
            referralVerificationError: 'Sorry your referral link is invalid',
        },
        profileStep: {
            title: 'Your details',
            description: 'to manage your insurance later',
            kidsInfo:
                'You will be the account holder on behalf of the Kids’ policies. You must be over 18 and a parent or legal guardian of the children you’re buying cover for.',
            alreadyHaveAccount: 'Already have an account? {signInHere}',
            signInHere: 'Sign in here',
            email: {
                label: 'Email Address',
            },
            firstName: {
                label: 'First name',
            },
            lastName: {
                label: 'Last name',
            },
            gender: {
                label: 'Select your gender',
            },
            state: {
                label: 'State you live in',
            },
            residencyStatus: {
                label: 'Residency status',
            },
            dropdownPlaceholder: 'Please select',
            dobError: {
                errorInvalidDate: 'Sorry, the date doesn’t look right',
                errorMustBeOver18: 'Sorry, you need to be 18 or over to purchase Flip insurance',
                errorMustBeUnder65: 'Sorry, you need to be 64 or under to purchase Flip insurance',
            },
            emailError: "Sorry, that email doesn't look right",
            existingActiveUserError: {
                heading: 'You already have a Flip account.',
                description: 'Click sign in to continue',
                signInCta: 'Sign in',
                close: 'Close',
            },
            agreeTerms: `By continuing you agree to the <a href="${ExternalLinks.termsAndConditions}" target="_blank">Flip Terms of Use</a> and <a href="${ExternalLinks.privacyPolicy}" target="_blank">Privacy Policy</a>.`,
        },
        whosCoveredStep: {
            title: 'Who is covered?',
            description: 'Select who this cover is for',
            disclosureSummary: 'Kid {index}',
        },
        reviewCoverStep: {
            title: 'Cover details',
            benefitsCTA: "Check what we pay out for injuries and the injuries we don't cover",
            exclusionsCTA: "Check what we don't cover",
            ctaLabel: 'Continue to payment',
            coverSummaryTitle: 'Cover summary',
            subtotal: 'Subtotal',
            total: 'Total: ',
        },
        paymentStep: {
            title: 'Payment details',
        },
    },
});

export default cartContent;
